// assets
import { IconDashboard, IconUsers, IconCalendarEvent } from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconUsers, IconCalendarEvent };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            url: '/clients',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'schedule',
            title: 'Schedule',
            type: 'collapse',
            url: '/schedule',
            icon: icons.IconCalendarEvent,
            children: [
                {
                    id: 'summer-schedule',
                    title: 'Summer Schedule',
                    type: 'item',
                    url: '/summer-schedule',
                    breadcrumbs: false
                },
                {
                    id: 'year-round-sessions',
                    title: 'Year Round Sessions',
                    type: 'item',
                    url: '/year-round-sessions',
                    breadcrumbs: false
                },
            ]
        }
    ]
};

export default dashboard;
