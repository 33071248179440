// material-ui
import { useTheme } from '@mui/material/styles';

// import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/splash_logo.svg';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <img src={logo} alt="Splash Aquatics" width="175" />

    );
};

export default Logo;
