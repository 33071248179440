import { useRoutes, useNavigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import { useAuth } from 'hooks/AuthContext';
import { useEffect } from 'react';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            if (window.location.pathname === '/login') {
                navigate('/', { replace: true });
            }
        }
        if (!currentUser) {
            if (window.location.pathname !== '/login') {
                navigate('/login', { replace: true });
            }
        }
    }, [currentUser, navigate]);


    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
