import { createContext, useContext } from "react";
import { supabase } from "supabaseClient";


const SupabaseContext = createContext();

export function useSupabase() {
    return useContext(SupabaseContext);
}

export function SupabaseProvider({ children }) {

    const getAllBookings = async () => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('status', 'booked')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
        if (error) {
            console.log('error', error)
        }

        return data
    }

    const getAllBookingsCount = async () => {
        const { count, error } = await supabase
            .from('bookings')
            .select('*', { count: 'exact' })
            .eq('status', 'booked')
        if (error) {
            console.log('error', error)
        }
        return count
    }

    const getBookingsForUser = async (uid) => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('user_id', uid)
            .eq('status', 'booked')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
        if (error) {
            console.log('error: ', error)
        }

        return data
    }

    const getPendingBookingsCount = async () => {
        const { count, error } = await supabase
            .from('bookings')
            .select('*', { count: 'exact' })
            .eq('status', 'pending')
        if (error) {
            console.log('error', error)
        }
        return count
    }

    const getBookingsByDay = async (date) => {
        const startOfDay = date.startOf('day');
        const endOfDay = date.endOf('day');
        console.log('startOfDay', startOfDay.toDate());
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('status', 'booked')
            .lte('booking_time', endOfDay)
            .gte('booking_time', startOfDay)
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
            .order('booking_time', { ascending: true })
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const getUnbookedLessonCount = async () => {
        // Based on the instructor availability and the pool capacity, calculate the number of lessons that can still be booked
        const availableLessons = [];
        const instructorAvailability = await supabase
            .from('instructor_availability')
            .select('*')    
        // TODO: Iterate through the instructor availability and calculate the available lessons
    }

    const getAvailableServices = async () => {
        const {data, error } = await supabase
            .from('services')
            .select('*')
        if(error) {
            return error;
        }
        return data
    }

    const getAvailablePools = async () => {
        const {data, error} = await supabase
            .from('pools')
            .select('*')
        if(error) {
            return error
        }
        return data
    }

    const getInstructors = async () => {
        const {data, error} = await supabase
            .from('instructors')
            .select('*')
        if(error) {
            return error
        }
        return data
    }

    const addBookingForUser = async (booking) => {
        const { data, error } = await supabase
            .from('bookings')
            .insert(booking)
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const value = {
        getAllBookings,
        getAllBookingsCount,
        getPendingBookingsCount,
        getBookingsByDay,
        getAvailableServices,
        getAvailablePools,
        getInstructors,
        addBookingForUser,
        getBookingsForUser,
    }

    return (
        <SupabaseContext.Provider value={value}>
            {children}
        </SupabaseContext.Provider>
    )
};