// assets
import { IconKey, IconFriends, IconLayoutKanban, IconBrandTabler, IconPool, IconSwimming } from '@tabler/icons-react';

// constant
const icons = {
    IconKey,
    IconFriends,
    IconLayoutKanban,
    IconBrandTabler,
    IconPool,
    IconSwimming
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'company',
    title: 'Company',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        // {
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: icons.IconKey,

        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'Login',
        //             type: 'item',
        //             url: '/pages/login/login3',
        //             target: true
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Register',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: true
        //         }
        //     ]
        // },
        {
            id: 'splash-team',
            title: 'Splash Team',
            type: 'collapse',
            icon: icons.IconFriends,
            children: [
                {
                    id: 'new-team-member',
                    title: 'Add New Team Member',
                    type: 'item',
                    url: '/new-team-member',
                },
                {
                    id: 'team-members',
                    title: 'Team Members',
                    type: 'item',
                    url: '/splash-team',
                },
            ]
        },
        {
            id: 'splash-pools',
            title: 'Splash Pools',
            type: 'collapse',
            icon: icons.IconPool,
            children: [
                {
                    id: 'new-pool',
                    title: 'Add New Pool',
                    type: 'item',
                    url: '/new-pool',
                },
                {
                    id: 'pools',
                    title: 'Pools',
                    type: 'item',
                    url: '/splash-pools',
                },
            ]
        },
        {
            id: 'year-round-sessions',
            title: 'Year Round Sessions',
            type: 'collapse',
            icon: icons.IconSwimming,
            children: [
                {
                    id: 'new-session',
                    title: 'Add New Session',
                    type: 'item',
                    url: '/new-session',
                },
                {
                    id: 'sessions',
                    title: 'Add New Schedule',
                    type: 'item',
                    url: '/new-schedule',
                },
                {
                    id: 'new-lesson-type',
                    title: 'Add New Lesson Type',
                    type: 'item',
                    url: '/new-lesson-type',
                },
                {
                    id: 'sessions',
                    title: 'Sessions',
                    type: 'item',
                    url: '/year-round-sessions',
                },
            ]
        },
        // {
        //     id: 'admin-tasks',
        //     title: 'Admin Tasks',
        //     type: 'item',
        //     url: '/tasks',
        //     icon: icons.IconLayoutKanban,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'website-data',
        //     title: 'Website Data',
        //     type: 'item',
        //     url: '/website-data',
        //     icon: icons.IconBrandTabler,
        //     breadcrumbs: false
        // },
    ]
};

export default pages;
